<template>
  <div class="success-container">
    <div><img :src="img" class="img" /></div>
    <div>手机号码已更改，</div>
    <div>您即刻起可使用您手机号码登录账户</div>

    <van-button round block type="info" native-type="submit" class="btn" @click="returnHome"
      ><span class="font">完成</span></van-button
    >
  </div>
</template>

<script>
import Vue from 'vue'
import { Button } from 'vant'

Vue.use(Button)
export default {
  data() {
    return {
      img: require('@/assets/images/user/cg.png'),
    }
  },
  methods: {
    returnHome() {
      this.$router.push({ path: this.$store.state.common.defaultIndex })
    },
  },
}
</script>

<style lang="less" scoped>
.success-container {
  height: 100vh;
  background-color: #f5f6fa;
  padding: 0 0.52rem 0 0.48rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.36rem /* 18/50 */;
  line-height: 0.54rem /* 27/50 */;
  font-weight: 500;
  .img {
    width: 1.4rem /* 70/50 */;
    height: 1.96rem /* 98/50 */;
    margin-top: 1.72rem /* 86/50 */;
    margin-bottom: 0.38rem /* 19/50 */;
  }
  .btn {
    width: 100%;
    margin-top: 1.78rem /* 89/50 */;

    .font {
      font-size: 0.34rem /* 17/50 */;
      line-height: 0.48rem /* 24/50 */;
    }
  }
}
</style>
